import styled from 'styled-components';

export interface ContainerProps {
  isFormVisible: boolean;
}

export const Container = styled.div<ContainerProps>`
  background-color: rgba(62, 62, 62, 1);
  display: flex;
  flex: 1;

  .login-box {
    width: 300px;
    height: 390px;
    margin: auto;
    padding: 24px;
    border-radius: 14px;
    background-color: rgba(240,	236, 91, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #343434;

    transition: min-height 2s;

    .logo {
      width: 140px;
      -webkit-box-shadow: 0px 0px 42px -12px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 42px -12px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 42px -12px rgba(0,0,0,0.75);
      border-radius: 24px;
    }

    h1 {
      margin: 32px 0;
  }
    }


  .loader {
    margin: auto;
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    text-align: center;
    opacity: ${({ isFormVisible }) => !isFormVisible ? 0 : 1};

    visibility: ${({ isFormVisible }) => !isFormVisible ? 'hidden' : 'visible'};
    transition: visibility 0.5s, opacity 0.5s;

  }
  .form {
    position: relative;
    width:100%;
  }
`;


export const Form = styled.form<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  opacity: ${({ isFormVisible }) => isFormVisible ? 0 : 1};

  visibility: ${({ isFormVisible }) => isFormVisible ? 'hidden' : 'visible'};
  transition: visibility 0.5s, opacity 0.5s;


`;

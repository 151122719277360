import React, { useState, useEffect, useCallback } from "react";
import Wrapper from "@components/Wrapper";
import Table from "@components/Table";

import api from "@services/api";

import { Container } from "./styles";

interface UsersProps {
  id: string;
  firstname: string;
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<UsersProps[] | undefined>(undefined);
  const [info, setInfo] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [order, setOrder] = useState<{ name: string; order: string }>({
    name: "id",
    order: "DESC",
  });

  const fields = [
    { name: "id", tableName: "users" },
    { name: "firstname" },
    { name: "email" },
    { name: "phone" },
    { name: "created_at" },
    { name: "active" },
  ];

  const fetchUsers = useCallback(async () => {
    try {
      const { data: usersFetched } = await api.get(
        `users/s/${page}/${limit}/${order?.name},${order?.order}`
      );
      const { data, ...rest } = usersFetched;

      setUsers(data);
      setInfo(rest);
    } catch (error) {
      console.error(error);
    }
  }, [page, limit, order]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    fetchUsers();
  }, [limit, page, fetchUsers]);

  const handleOrder = (index: number) => {
    setOrder({
      name: fields[index].name,
      order: order?.order === "ASC" ? "DESC" : "ASC" || "ASC",
    });
  };

  return (
    <Wrapper title="Users">
      <Container>
        <Table
          data={users}
          fields={fields}
          headers={[
            "ID",
            "Nome",
            "Endereço de E-mail",
            "Telefone",
            "Data de entrada",
            "Ativo",
          ]}
          info={info}
          goToPage={setPage}
          setLimit={setLimit}
          setOrder={handleOrder}
        />
      </Container>
    </Wrapper>
  );
};

export default Users;

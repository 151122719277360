import React, { ReactNode } from "react";

import { Container } from "./styles";

interface CardProps {
  title?: string;
  children?: ReactNode;
  footer?: string;
}

const Card: React.FC<CardProps> = ({ title, children }) => {
  return (
    <Container>
      <div className="card-header">{title}</div>
      <div className="card-body">{children}</div>
      <div className="card-footer"></div>
    </Container>
  );
};

export default Card;

import Wrapper from "@components/Wrapper";
import api from "@services/api";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { io } from "socket.io-client";

const Whatsapp: React.FC = () => {
  const [status, setStatus] = useState("");
  const [qrCode, setQrCode] = useState("");
  const whatsappApi = "https://whatsappservice.boralaa.com.br/";

  useEffect(() => {
    fetchWhatsappQRCode();
  }, []);

  useEffect(() => {
    socketWhatsapp();
  }, []);

  const socketWhatsapp = async () => {
    const socket = io(whatsappApi);

    socket.on("connect", () => {
      console.log("Connected: ", socket.id);
    });

    socket.on("updatedQr", (response) => {
      console.log(response);
      setQrCode(response.url);
    });

    socket.on("ready", (response) => {
      setStatus("Conectado");
      setQrCode("");
    });
  };

  const fetchWhatsappQRCode = async () => {
    try {
      const { data } = await axios.get(`${whatsappApi}qrcode`, {
        headers: {
          "app-key": "da39a3ee5e6b4b0d3255bfef95601890afd80709",
        },
        timeout: 5000,
      });
      if (data.message === "noqr") {
        setStatus("Carregando...");
      }
      if (data.url) {
        setStatus("Aguardando Login via Whatsapp");
        setQrCode(data.url);
      }
      if (data.status) setStatus(data.status);
    } catch (error: any) {
      if (error?.message.includes("timeout")) {
        setStatus("Serviço Off-line tente novamente em instantes");
      }

      console.error("Fetch QRCode Error: ", error);
    }
  };
  return (
    <Wrapper title="Whatsapp">
      <Container>
        Whatsapp Status: {status ? status : "Loading..."}
        {qrCode && (
          <div style={{ marginTop: 20 }}>
            <h3 style={{ marginBottom: 20 }}>
              Escaneie o código do whatsapp abaixo
            </h3>
            <img src={qrCode} alt="qrcode" width={300} height={300} />
          </div>
        )}
      </Container>
    </Wrapper>
  );
};

export default Whatsapp;

const Container = styled.div``;

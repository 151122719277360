import React, { SelectHTMLAttributes, useEffect, useState } from "react";

import { Container } from "./styles";

type SelectProps = {
  label?: string;
  options: ISelectProps[];
} & SelectHTMLAttributes<HTMLSelectElement>;

type ISelectProps = {
  value: number;
  label: string;
};

const Select = (props: SelectProps) => {
  const [options, setOptions] = useState<null | ISelectProps[]>(null);
  const [value] = useState<any>(props.defaultValue);

  useEffect(() => {
    props.options && setOptions(props.options);
  }, [props.options]);

  return (
    <Container>
      <label htmlFor={props.id}>{props.label}</label>
      <select defaultValue={value}>
        {options &&
          options?.map((v: ISelectProps) => {
            return (
              <option key={v.value} value={v.value}>
                {v.label}
              </option>
            );
          })}
      </select>
    </Container>
  );
};

export default Select;

import styled from 'styled-components';


export const Container = styled.section`
  .card {
    background-color: white;
    padding: 24px;
    border-radius: 14px;
    max-width: 100%;
  }

  .avatar {
    width: 200px;
    height: 250px;
    margin-right: 24px;
    border-radius: 10px;
  }

  .form-row {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    flex-wrap: wrap;
  }

  .form-col {
    flex: 1;
  }


  label {
    font-size: 18px;
    font-weight: bold;

  }

  input {
    width: 90%;
  }
`;

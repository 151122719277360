import React from "react";

// import { Container } from './styles';

import { useHistory } from "react-router-dom";
import { useAuth } from "@hooks/auth";

const Logout: React.FC = () => {
  const history = useHistory();
  const auth = useAuth();

  auth.logout();
  history.push("/");

  return <div />;
};

export default Logout;

import React from "react";
import LogoImg from "@assets/logo.png";
import { useHistory } from "react-router-dom";
import { Container, Menu, MenuItem } from "./styles";
import { AiOutlineDashboard, AiOutlineUser, AiFillCar } from "react-icons/ai";
import { useAuth } from "@hooks/auth";

interface WrapperProps {
  children: React.ReactNode;
  title: string;
}

const Wrapper: React.FC<WrapperProps> = ({ children, title }) => {
  const history = useHistory();
  const auth = useAuth();
  const location = history.location.pathname;

  return (
    <Container>
      <Menu>
        <header>
          <img src={LogoImg} alt="" className="logo" />
        </header>
        <ul>
          <MenuItem
            selected={location === "/" ? true : false}
            onClick={() => history.push("/")}
          >
            <AiOutlineDashboard size={20} />
            Dashboard
          </MenuItem>
          <MenuItem
            selected={location === "/users" ? true : false}
            onClick={() => history.push("/users")}
          >
            <AiOutlineUser size={20} />
            Usuários
          </MenuItem>
          <MenuItem
            selected={location === "/rides" ? true : false}
            onClick={() => history.push("/rides")}
          >
            <AiFillCar size={20} />
            Caronas
          </MenuItem>

          <MenuItem
            selected={location === "/whatsapp" ? true : false}
            onClick={() => history.push("/whatsapp")}
          >
            <AiFillCar size={20} />
            Whatsapp
          </MenuItem>
        </ul>

        <footer>
          <p onClick={() => auth.logout()}>SAIR</p>
        </footer>
      </Menu>
      <div className="content">
        <div className="breadcumb">
          <h1>{title}</h1>
        </div>
        {children}
      </div>
    </Container>
  );
};

export default Wrapper;

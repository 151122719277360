import React, { useEffect, useState } from "react";
import Loader from "@components/Loader";
import { Container, SearchBar } from "./styles";

import { useHistory } from "react-router-dom";

interface InfoProps {
  total: string;
  perPage: number;
  page: number;
  lastPage: number;
}

interface FieldProps {
  name: string;
  childName?: string;
  tableName?: string;
}
interface TableProps {
  headers: string[];
  fields: FieldProps[];
  data: object[] | undefined;
  info?: InfoProps | undefined;
  goToPage(page: number): void;
  setLimit(page: number): void;
  setOrder(name: number): void;
}

const Table: React.FC<TableProps> = ({
  headers,
  data,
  fields,
  info,
  goToPage,
  setLimit,
  setOrder,
}) => {
  const [tableHeaders] = useState(headers);
  const [tableData, setTableData] = useState<object[] | undefined>(undefined);
  const [tableFields, setTableFields] = useState(fields);
  const [tableInfo, setTableInfo] = useState<InfoProps>();
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const history = useHistory();

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setTableFields(fields);
  }, [fields]);

  useEffect(() => {
    setTableInfo(info);
  }, [info]);

  useEffect(() => {
    if (tableData !== undefined) {
      setTableLoading(false);
    }
  }, [tableData]);

  const handleClick = (tf: FieldProps, tr: any) => {
    // console.log(tr, tf);
    if (tf.tableName) {
      if (tf.childName) {
        history.push(`${tf.tableName}/${tr.id}`);
      } else {
        history.push(`${tf.tableName}/${tr.id}`);
      }
    }
    // if (tableName) {
    // }
  };

  return (
    <Container>
      <SearchBar>
        <input
          placeholder="Digite o nome para pesquisa"
          type="text"
          name="search"
          id="search"
        />
      </SearchBar>
      <table>
        <thead>
          <tr>
            {tableHeaders?.map((th, i) => {
              return (
                <th onClick={() => setOrder(i)} key={th}>
                  {th}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableLoading ? (
            <tr>
              <td colSpan={tableHeaders.length} className="full">
                <Loader size={20} />
              </td>
            </tr>
          ) : tableData && tableData.length > 0 ? (
            tableData.map((tableRow: any) => {
              return (
                <tr key={tableRow.id}>
                  {tableFields.map((tf) => {
                    return (
                      <td
                        key={tf.name}
                        style={{
                          cursor: tf.tableName ? "pointer" : "default",
                        }}
                        onClick={() => handleClick(tf, tableRow[tf.name])}
                      >
                        {tf.childName
                          ? tableRow[tf.name][tf.childName]
                          : tableRow[tf.name]}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <td colSpan={tableHeaders.length} className="full">
              Nenhum registro encontrado
            </td>
          )}
        </tbody>
      </table>
      <div className="info">
        {info && info.page > 1 && (
          <span onClick={() => goToPage(info.page - 1)}>{"<"} Anterior</span>
        )}
        Mostrando <span>{tableData?.length}</span> de{" "}
        <span>{tableInfo?.total}</span> registros, limitado a{" "}
        <span>
          <select
            defaultValue={tableInfo?.perPage}
            onChange={(select) => setLimit(Number(select.target.value))}
          >
            {tableInfo?.perPage}
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>{" "}
        registros por página
        {info && info.page < info.lastPage && (
          <span onClick={() => goToPage(info.page + 1)}> Próximo {">"}</span>
        )}
      </div>
    </Container>
  );
};

export default Table;

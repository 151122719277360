import axios, { AxiosError, AxiosRequestConfig } from "axios";
import history from "@services/navigation";

const api = axios.create({
  baseURL: "https://apiv2.boralaa.com.br/",
  // baseURL: "http://127.0.0.1:3333/",
  timeout: 15000,
});

api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (!config.url?.endsWith("session/admin")) {
      const token = sessionStorage.getItem("@boralaa_admin:token");

      if (!token) {
        Promise.reject("token not provided");
      }

      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    console.log("RESERROR", error.response);
    if (error.response?.status === 401) {
      history.push("/logout");
      sessionStorage.clear();
    }

    Promise.reject(error);
  }
);

export default api;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .cards {
    display:flex;
    width:100%;
    flex-wrap: wrap;
    flex-grow: 2;
    justify-content: flex-start;

    .card {
      min-width: 300px;
      margin-bottom: 30px;
      margin-right: 30px;
    }
  }
`;

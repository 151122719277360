import React, { InputHTMLAttributes } from "react";

// import { Container } from './styles';

type InputProps = {
  label: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = (props: InputProps) => {
  return (
    <div className="form-col">
      <label htmlFor={props.id}>{props.label}</label>
      <input {...props} value={props.value || ""} onChange={() => {}} />
    </div>
  );
};

export default Input;

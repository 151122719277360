import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 14px;

  .card-header {
    border-bottom:  1px solid #e1e1e1;
    padding: 14px;
    text-align:center;
    font-weight: bold;
  }

  .card-body {
    padding: 14px;
  }

  .card-footer {

    padding: 14px;
  }
`;

import React, { ChangeEvent, FormEvent, useCallback, useState } from "react";

import { Container, Form } from "./styles";
// import { useHistory } from "react-router-dom";
import { useAuth } from "@hooks/auth";

import LogoImg from "@assets/logo.png";
import Loader from "@components/Loader";

interface LoginData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });
  // const history = useHistory();
  const auth = useAuth();

  const setEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLoginData((prevState) => ({
      ...prevState,
      email: event.target.value,
    }));
  }, []);

  const setPassword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLoginData((prevState) => ({
      ...prevState,
      password: event.target.value,
    }));
  }, []);

  const handleLogin = (event: FormEvent) => {
    event.preventDefault();
    auth.login(loginData);
  };

  return (
    <Container isFormVisible={auth.data.loading}>
      <div className="login-box">
        <img src={LogoImg} alt="" className="logo" />
        <h1 className="title">Boraa Láá</h1>
        <div className="form">
          <Form
            isFormVisible={auth.data.loading}
            action=""
            onSubmit={handleLogin}
            className="login"
          >
            <input
              type="text"
              value={loginData.email}
              onChange={setEmail}
              placeholder="E-mail"
              name="email"
              id="email"
            />
            <input
              type="password"
              value={loginData.password}
              onChange={setPassword}
              placeholder="Senha"
              name="password"
              id="password"
            />

            <button className="submit">ENTRAR</button>
          </Form>

          <div className="loader">
            <Loader size={30} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Login;

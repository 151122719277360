import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #f1f1f1;
  width: 100%;

  .content {
    padding: 24px;
    width: 100%;

    .breadcumb {
      margin-bottom: 24px;
    }
  }
`;

export const Menu = styled.div`
  background-color: rgba(62, 62, 62, 1);
  width: 250px;
  border-right: 1px solid #c1c1c1;
  display: flex;
  flex-direction: column;
  color: #FFF;
  align-items: center;

  header {
    padding: 24px 0;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      width: 80px;
      height: auto;
    }
  }

  h2 {
    width: 100%;
    text-align: center;
    border-top: 1px solid #343434;
    border-bottom: 1px solid #343434;
    margin-bottom: 24px;
  }

  ul {
    list-style: none;
    flex: 1;
    text-transform: uppercase;
    padding: 8px;
    text-align: center;
  }



  footer {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #c1c1c1;
    font-weight: bold;
    font-size: 18px;
    width: 100%;

    &:hover {
      cursor: pointer;
      background-color: rgba(240,	236, 91, 1);
      color: #343434;
    }
  }
`;

export const MenuItem = styled.li`
  margin: 8px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px;
  font-weight:bold;
  font-size: 20px;
  background-color: rgba(70, 70, 70, 1);
  color: ${props => props.selected ? 'rgba(240,	236, 91, 1)' : '#ffffff '};
  ${props => props.selected && css`
    background-color: rgba(240,	236, 91, 1);
    color: #343434;
  `}

  svg {
    margin-bottom: 8px;
  }

  &:hover {
    cursor: pointer;
    color: #343434;
    background-color: rgba(240,	236, 91, 1);
  }
`;

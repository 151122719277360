import React, { useCallback, useEffect, useState } from "react";

import { Container } from "./styles";

import Wrapper from "@components/Wrapper";
import Loader from "@components/Loader";
import Card from "@components/Card";
import api from "@services/api";

interface DashboardData {
  totalUsers: string;
  totalUsersToday: string;
}

const Dashboard: React.FC = () => {
  const [dashboardInfo, setDashboardInfo] = useState<DashboardData | null>(
    null
  );

  const fetchDashboard = useCallback(async () => {
    try {
      const { data } = await api.get("dashboard");

      setDashboardInfo(data);
    } catch (error) {
      console.error("ERRORED", error);
    }
  }, []);
  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  return (
    <Wrapper title="Dashboard">
      <Container>
        <div className="cards">
          <div className="card">
            <Card title="Usuários cadastrados hoje">
              <p
                style={{
                  fontSize: 40,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {dashboardInfo?.totalUsersToday || <Loader />}
              </p>
            </Card>
          </div>

          <div className="card">
            <Card title="Usuários cadastrados ao total">
              <p
                style={{
                  fontSize: 40,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {dashboardInfo?.totalUsers || <Loader />}
              </p>
            </Card>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Dashboard;

import { createGlobalStyle } from "styled-components";

const globalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  html, body, #App, #root {
    height: 100%;
    display: flex;
    flex: 1;
    font-family: Arial, Helvetica, sans-serif;
  }

  input {
    display: flex;
    flex: 1;
    padding: 12px;
    font-size: 18px;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    margin-bottom: 4px;
  }

  select {
    display: flex;
    flex: 1;
    padding: 24px;
    font-size: 34px;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    margin-bottom: 4px;
  }

  button {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 12px;
    font-size: 18px;
    border: 0;
    border-radius: 6px;
    margin-bottom: 4px;
    background-color: rgba(255,255,255,1);
    font-weight: bold;
  }

  .bg-success {
    background-color: rgba(103,	228,	128, 1);
  }
`;

export default globalStyles;

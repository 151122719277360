import React, { useState, useEffect, useCallback } from "react";
import Wrapper from "@components/Wrapper";
import Table from "@components/Table";

import api from "@services/api";

import { Container } from "./styles";

interface RidesProps {
  id: string;
  from_address: string;
  to_address: string;
}

const Rides: React.FC = () => {
  const [rides, setRides] = useState<RidesProps[] | undefined>(undefined);
  const [info, setInfo] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<{ name: string; order: string }>({
    name: "id",
    order: "DESC",
  });
  const fields = [
    { name: "id" },
    { name: "users", childName: "firstname", tableName: "users" },
    { name: "from_address" },
    { name: "to_address" },
    { name: "created_at" },
  ];

  const fetchRides = useCallback(async () => {
    try {
      const { data: ridesFetched } = await api.get(
        `rides/s/${page}/${limit}/${order?.name},${order?.order}`
      );
      const { data, ...rest } = ridesFetched;

      setRides(data);
      setInfo(rest);
    } catch (error) {
      console.error(error);
    }
  }, [page, limit, order]);

  useEffect(() => {
    fetchRides();
  }, [fetchRides]);

  useEffect(() => {
    fetchRides();
  }, [limit, page, fetchRides]);

  const handleOrder = (index: number) => {
    setOrder({
      name: fields[index].name,
      order: order?.order === "ASC" ? "DESC" : "ASC" || "ASC",
    });
  };

  return (
    <Wrapper title="Rides">
      <Container>
        <Table
          data={rides}
          fields={fields}
          headers={["ID", "motorista", "Saida", "Chegada", "Criado em"]}
          info={info}
          goToPage={setPage}
          setLimit={setLimit}
          setOrder={handleOrder}
        />
      </Container>
    </Wrapper>
  );
};

export default Rides;

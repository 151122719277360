import Wrapper from "@components/Wrapper";
import { Container } from "./styles";
import api from "@services/api";
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Input from "@components/Input";
import Select from "@components/Select";
import Loader from "@components/Loader";

const options = [
  {
    value: 0,
    label: "Não",
  },
  { value: 1, label: "Sim" },
];

const User: React.FC = () => {
  const { id } = useParams() as any;
  const [user, setUser] = useState<any>(null);
  const [avatar, setAvatar] = useState<string | null>(null);

  const fetchUser = useCallback(async () => {
    try {
      const { data } = await api.get(`users/${id}`);

      if (data.avatar) {
        const { data: avatarUrl } = await api.get(`avatar/${id}`);

        setAvatar(avatarUrl);
      }

      setUser(data);
    } catch (error) {
      console.error("erroraqui", error);
    }
  }, [id]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <Wrapper title={`ID do Usuário: ${id}`}>
      <Container>
        {user ? (
          <>
            <div className="card">
              <div style={{ display: "flex" }}>
                {avatar && <img className="avatar" src={avatar} alt="avatar" />}
                <div style={{ flexDirection: "column", flex: 1 }}>
                  <div className="form-row">
                    <Input
                      label="Primeiro Nome"
                      id="firstname"
                      value={user?.firstname}
                    />
                    <Input
                      label="Sobrenome"
                      id="lastname"
                      value={user?.lastname}
                    />
                  </div>

                  <div className="form-row">
                    <Input
                      label="Endereço"
                      id="address"
                      value={user?.address}
                    />
                    <Input label="Número" id="number" value={user?.number} />
                    <Input
                      label="Complemento"
                      id="complement"
                      value={user?.complement}
                    />
                    <Input label="CEP" id="zip" value={user?.zip} />
                  </div>

                  <div className="form-row">
                    <Input label="Cidade" id="city" value={user?.city} />
                    <Input label="Estado" id="state" value={user?.state} />
                    <Input label="País" id="country" value={user?.country} />
                    <Input
                      label="Bairro"
                      id="district"
                      value={user?.district}
                    />
                  </div>

                  <div className="form-row">
                    <Select
                      label="Fumar"
                      options={options}
                      defaultValue={user?.smoke}
                    />
                    <Select
                      label="Conversar"
                      options={options}
                      defaultValue={user?.talk}
                    />
                    <Select
                      label="Animais"
                      options={options}
                      defaultValue={user?.animal}
                    />
                    <Select
                      label="Crianças"
                      options={options}
                      defaultValue={user?.child}
                    />
                  </div>

                  <div className="form-row">
                    <Select
                      label="Usuário Ativo?"
                      options={options}
                      defaultValue={user?.active}
                    />

                    <Select
                      label="Aceitou termos de servico?"
                      options={options}
                      defaultValue={!!user.tos ? 1 : 0}
                    />

                    <Select
                      label="Aceitou receber notificações no whatsapp?"
                      options={options}
                      defaultValue={user?.ride_notifications}
                    />
                  </div>

                  <div className="form-row">
                    <Input
                      label="Dinheiro em Conta"
                      id="balance"
                      value={user?.balance}
                    />
                  </div>
                  <div className="row">
                    {/* <button className="submit">SALVAR</button> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </Wrapper>
  );
};

export default User;

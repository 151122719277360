import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import api from "@services/api";

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthData {
  token?: string;
  email?: string;
  loading: boolean;
}

interface LoginData {
  email: string;
  password: string;
}

interface AuthContextProps {
  data: AuthData;
  login({ email, password }: LoginData): Promise<void>;
  logout(): void;
}

const initialData = {
  loading: false,
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [data, setData] = useState<AuthData>(initialData);

  useEffect(() => {
    const fetchDataFromStorage = async () => {
      const token = sessionStorage.getItem("@boralaa_admin:token");
      const email = sessionStorage.getItem("@boralaa_admin:email");

      if (token && token !== "undefined" && email && email !== "undefined") {
        setData({ token, email, loading: false });
      } else {
        setData({ loading: false });
      }
    };

    fetchDataFromStorage();
  }, []);

  const login = useCallback(async ({ email, password }: LoginData) => {
    try {
      setData({ loading: true });
      const { data } = await api.post("session/admin", {
        email,
        password,
      });
      console.log("QAUI", data);
      setData({
        token: data.token,
        email,
        loading: false,
      });

      sessionStorage.setItem("@boralaa_admin:token", data.token);
      sessionStorage.setItem("@boralaa_admin:email", email);
    } catch (error) {
      alert("usuario ou senha incorretos");
      console.log(error);
      setData({
        loading: false,
      });
    }
  }, []);

  const logout = useCallback(() => {
    sessionStorage.clear();
    setData({ loading: false });
  }, []);

  return (
    <AuthContext.Provider value={{ data, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("You cant use context outside react function");
  }

  return context;
};

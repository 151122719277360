import React from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "@pages/Dashboard";
import Users from "@pages/Users";
import Rides from "@pages/Rides";
import Logout from "@pages/Logout";
import User from "@pages/User";
import Whatsapp from "@pages/Whatsapp";

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Dashboard} />
      <Route path="/users" exact component={Users} />
      <Route path="/users/:id" component={User} />
      <Route path="/rides" component={Rides} />
      <Route path="/whatsapp" component={Whatsapp} />
      <Route path="/logout" component={Logout} />
    </Switch>
  );
};

export default routes;

import React from "react";
import Routes from "@routes/index";
import GlobalStyles from "@styles/globalStyles";
import AppProvider from "@hooks/index";

function App() {
  return (
    <AppProvider>
      <GlobalStyles /> <Routes />
    </AppProvider>
  );
}

export default App;

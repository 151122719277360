import React from "react";
import { Router } from "react-router-dom";
import AppRoutes from "@routes/app.routes";
import AuthRoutes from "@routes/auth.routes";
import { useAuth } from "@hooks/auth";

import history from "@services/navigation";

const Routes: React.FC = () => {
  const auth = useAuth();

  /* if (auth.data?.loading) {
    return <h1>Carregando</h1>;
  } */

  return (
    <Router history={history}>
      {auth.data?.token ? <AppRoutes /> : <AuthRoutes />}
    </Router>
  );
};

export default Routes;
